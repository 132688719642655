/* eslint-disable max-len */
import { CommonModule } from '@angular/common';
import { Component, input, OnInit } from '@angular/core';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { lucidePhone } from '@ng-icons/lucide';
import { PHONE_HOURS, PHONE_NUMBERS, PHONE_HOURS_CA, PhoneHours } from '../../common/constants/contact-constants';
import { PhonePipe } from '../../common/pipes/phone-number/phone.pipe';
import { ContactCardVariants } from './contact-card-utils';

@Component({
  selector: 'app-contact-card',
  standalone: true,
  imports: [PhonePipe, NgIconComponent, CommonModule],
  providers: [provideIcons({ lucidePhone })],
  styles: `
    .contact-grid-container {
      display: grid;
      grid-template-columns: auto 1fr;
    }
  `,
  template: `
    <!-- header variant -->
    @if (variant() === 'header') {
      <div class="{{ class() }}" data-testid="contact-us-header">
        <div class="inline-flex items-center">
          <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <!--
            //
            // NOTE: Need these lint ignores for the svg path
            // They're set this way so they can both be applied to the same line, and max-len seemed 
            // lower risk to set on the file
            //
          -->
            <!-- prettier-ignore -->
            <path d="M20.9999 15.9201V18.9201C21.0011 19.1986 20.944 19.4743 20.8324 19.7294C20.7209 19.9846 20.5572 20.2137 20.352 20.402C20.1468 20.5902 19.9045 20.7336 19.6407 20.8228C19.3769 20.912 19.0973 20.9452 18.8199 20.9201C15.7428 20.5857 12.7869 19.5342 10.1899 17.8501C7.77376 16.3148 5.72527 14.2663 4.18993 11.8501C2.49991 9.2413 1.44818 6.27109 1.11993 3.1801C1.09494 2.90356 1.12781 2.62486 1.21643 2.36172C1.30506 2.09859 1.4475 1.85679 1.6347 1.65172C1.82189 1.44665 2.04974 1.28281 2.30372 1.17062C2.55771 1.05843 2.83227 1.00036 3.10993 1.0001H6.10993C6.59524 0.995321 7.06572 1.16718 7.43369 1.48363C7.80166 1.80008 8.04201 2.23954 8.10993 2.7201C8.23656 3.68016 8.47138 4.62282 8.80993 5.5301C8.94448 5.88802 8.9736 6.27701 8.89384 6.65098C8.81408 7.02494 8.6288 7.36821 8.35993 7.6401L7.08993 8.9101C8.51349 11.4136 10.5864 13.4865 13.0899 14.9101L14.3599 13.6401C14.6318 13.3712 14.9751 13.1859 15.3491 13.1062C15.723 13.0264 16.112 13.0556 16.4699 13.1901C17.3772 13.5286 18.3199 13.7635 19.2799 13.8901C19.7657 13.9586 20.2093 14.2033 20.5265 14.5776C20.8436 14.9519 21.0121 15.4297 20.9999 15.9201Z"
            fill="var(--color-primary)"
            stroke="var(--color-neutral-900)"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round" />
          </svg>
          <h3 class="ml-2 font-semi-bold">Contact Us</h3>
        </div>
        <p class="mt-8">
          Speak with a licensed agent
          <a href="tel:{{ phoneNumber }}" class="outline-none focus:ring-2 focus:ring-secondary"
            ><span data-testid="phone-number-header-modal" class="mx-1 font-bold">{{ phoneNumber | phone }}</span></a
          >
        </p>
        <div class="contact-grid-container mt-6 gap-3">
          <div>
            @if (hours.salesMF) {
              <div class="text-right font-bold">Mon - Fri</div>
            }
            @if (hours.salesSat) {
              <div class="text-right font-bold">Sat</div>
            }
          </div>
          <div>
            @if (hours.salesMF) {
              <div data-testid="modal-mf-hours">{{ hours.salesMF }}</div>
            }
            @if (hours.salesSat) {
              <div data-testid="modal-sat-hours">{{ hours.salesSat }}</div>
            }
          </div>
        </div>
      </div>
    }

    <!-- ejection variant -->
    @else if (variant() === 'ejection') {
      <div class="card card--primary hidden p-300 md:block" data-testid="contact-us-ejection-wide">
        <ng-container *ngTemplateOutlet="defaultWide"></ng-container>
      </div>
      <div class="md:hidden" data-testid="contact-us-ejection-mobile">
        <a href="tel:{{ phoneNumber }}" data-testid="phone-number-ejection-mobile" class="button button--sm button--primary w-full justify-center py-4 text-base">Call {{ phoneNumber | phone }}</a>
        <ng-container *ngTemplateOutlet="hoursGridCentered"></ng-container>
      </div>
    }

    <!-- default variant -->
    @else {
      <ng-container *ngTemplateOutlet="defaultWide"></ng-container>
    }

    <!-- templates -->
    <ng-template #defaultWide>
      <div class="{{ class() }}" data-testid="contact-us-default">
        <div class="text-center">
          <a href="tel:{{ phoneNumber }}" data-testid="phone-number-default" class="font-bold outline-none focus:ring-2 focus:ring-secondary">{{ phoneNumber | phone }}</a>
        </div>
        <ng-container *ngTemplateOutlet="hoursGridCentered"></ng-container>
      </div>
    </ng-template>

    <ng-template #hoursGridCentered>
      @if (hours.salesMF) {
        <div class="mt-300 flex justify-center" data-testid="hours-grid-mf">
          <div class="min-w-24 pr-300 text-right font-bold">Mon - Fri</div>
          <div data-testid="mf-hours" class="text-left">{{ hours.salesMF }}</div>
        </div>
      }
      @if (hours.salesSat) {
        <div class="flex justify-center" data-testid="hours-grid-sat">
          <div class="min-w-24 pr-300 text-right font-bold">Sat</div>
          <div data-testid="sat-hours" class="text-left">{{ hours.salesSat }}</div>
        </div>
      }
    </ng-template>
  `,
})
export class ContactCardComponent implements OnInit {
  /**
   * @description - The style `class` of the contact card wrapper.
   * @default - ''
   * @note - Flexible styling for use across different containers.
   */
  public class = input<string>('');

  /**
   * @description - The contact card variant.
   * @default - undefined
   */
  public variant = input<ContactCardVariants>();

  /**
   * @description - Show California hours instead of default hours?
   * @default - undefined
   */
  public isCaHours = input<boolean | undefined>();

  public phoneNumber = PHONE_NUMBERS.sales;
  public hours: PhoneHours = PHONE_HOURS;

  ngOnInit(): void {
    if (this.isCaHours()) {
      this.hours = PHONE_HOURS_CA;
    }
  }
}
